
<template>
  <div class="grid row">
    <div class="flex xs12">
      <va-card title="Редагування HomePage" class="fill-height">
        <div>
          <div class="row">
            <div class="flex xs12">
              <va-tabs v-model="tabValue" style="width: 100%; min-width: 250px; margin-bottom: 25px;">
                <va-tab
                  v-for="title in tabTitles"
                  :key="title"
                >
                  {{title}}
                </va-tab>
              </va-tabs>
              <div class="flex xs12" v-if="tabValue === 0">
                <va-card class="mb-3" title="Top Image">
                  <div v-if="topImageExisting">
                    <a style="display: block;" target="_blank" :href="topImageExisting"><img style="max-width: 400px;" :src="topImageExisting"></a>
                    <div><va-button small color="danger" @click="topImageExisting = deleteImage(topImageId) ? '' : topImageExisting">Видалити</va-button></div>
                  </div>
                  <span>Завантажити нове зображення</span>
                  <va-file-upload
                    type="gallery"
                    dropzone
                    @input="delete errors.topImage"
                    v-model="topImage"
                    style="margin-bottom: 25px;"
                  />
                  <va-button @click="uploadImage('topImage', 'top-image', 'AboutCompany[topImage]', topImage[0])">Завантажити</va-button>
                  <div v-if="errors.topImage" style="color: red;">{{ errors.topImage[0] }}</div>
                </va-card>
                <va-card class="mb-3" title="Top Mobile Image">
                  <div v-if="topImageMobileExisting">
                    <a style="display: block;" target="_blank" :href="topImageMobileExisting"><img style="max-width: 400px;" :src="topImageMobileExisting"></a>
                    <div><va-button small color="danger" @click="topImageMobileExisting = deleteImage(topImageMobileId) ? '' : topImageMobileExisting">Видалити</va-button></div>
                  </div>
                  <span>Завантажити нове зображення</span>
                  <va-file-upload
                    type="gallery"
                    dropzone
                    @input="delete errors.topImageMobile"
                    v-model="topImageMobile"
                    style="margin-bottom: 25px;"
                  />
                  <va-button @click="uploadImage('topMobileImage', 'top-mobile-image', 'AboutCompany[topMobileImage]', topImageMobile[0])">Завантажити</va-button>
                  <div v-if="errors.topMobileImage" style="color: red;">{{ errors.topMobileImage[0] }}</div>
                </va-card>
                <va-input
                  label="Breadcrumb Label *"
                  v-model="breadcrumbLabel"
                  @input="delete errors.breadcrumbLabel"
                  :error="!!errors.breadcrumbLabel"
                  :error-messages="errors.breadcrumbLabel"
                />
                <va-input
                  label="Title *"
                  v-model="title"
                  @input="delete errors.title"
                  :error="!!errors.title"
                  :error-messages="errors.title"
                />
                <va-input
                  type="textarea"
                  label="Short description*"
                  v-model="shortDescription"
                  @input="delete errors.shortDescription"
                  :error="!!errors.shortDescription"
                  :error-messages="errors.shortDescription"
                />
                <va-input
                  label="Advantages Block Title *"
                  v-model="advantagesBlockTitle"
                  @input="delete errors.advantagesBlockTitle"
                  :error="!!errors.advantagesBlockTitle"
                  :error-messages="errors.advantagesBlockTitle"
                />
                <va-input
                  type="textarea"
                  label="Advantages Block Description"
                  v-model="advantagesBlockDescription"
                  @input="delete errors.advantagesBlockDescription"
                  :error="!!errors.advantagesBlockDescription"
                  :error-messages="errors.advantagesBlockDescription"
                />
                <va-card class="mb-3" title="Advantages Block Items">
                  <va-button
                    class="mb-3"
                    :to="{ name: '1lk-about-advantage-edit' }"
                  >
                    Додати
                  </va-button>
                  <va-button
                    class="mb-3"
                    :to="{ name: '1lk-about-advantage' }"
                  >
                    Всі
                  </va-button>
                  <div class="row">
                    <div
                      v-for="advantage in advantages"
                      :key="advantage.id"
                      stripe="success"
                      class="flex xs4 mb-2">
                      <va-card
                      >
                        <div class="mb-1">Content : {{ advantage.content }} </div>
                        <va-button
                          small
                          color="info"
                          :to="{ name: '1lk-about-advantage-edit', params: { id: advantage.id } }"
                        >
                          Редагувати
                        </va-button>
                      </va-card>
                    </div>
                  </div>
                </va-card>
                <va-input
                  label="History Block Title *"
                  v-model="historyBlockTitle"
                  @input="delete errors.historyBlockTitle"
                  :error="!!errors.historyBlockTitle"
                  :error-messages="errors.historyBlockTitle"
                />
                <va-card class="mb-3" title="History Items">
                  <va-button
                    class="mb-3"
                    :to="{ name: '1lk-about-history-edit' }"
                  >
                    Додати
                  </va-button>
                  <va-button
                    class="mb-3"
                    :to="{ name: '1lk-about-history' }"
                  >
                    Всі
                  </va-button>
                  <div class="row">
                    <div
                      v-for="history in historyItems"
                      :key="history.id"
                      stripe="success"
                      class="flex xs4 mb-2">
                      <va-card
                      >
                        <div class="mb-1">Year : {{ history.year }} </div>
                        <va-button
                          small
                          color="info"
                          :to="{ name: '1lk-about-history-edit', params: { id: history.id } }"
                        >
                          Редагувати
                        </va-button>
                      </va-card>
                    </div>
                  </div>
                </va-card>
                <va-card class="mb-3" title="History Static Image">
                  <div v-if="historyStaticImgExisting">
                    <a style="display: block;" target="_blank" :href="historyStaticImgExisting"><img style="max-width: 400px;" :src="historyStaticImgExisting"></a>
                    <div><va-button small color="danger" @click="historyStaticImgExisting = deleteImage(historyStaticImgId) ? '' : historyStaticImgExisting">Видалити</va-button></div>
                  </div>
                  <span>Завантажити нове зображення</span>
                  <va-file-upload
                    type="gallery"
                    dropzone
                    @input="delete errors.historyStaticImage"
                    v-model="historyStaticImg"
                    style="margin-bottom: 25px;"
                  />
                  <va-button @click="uploadImage('historyStaticImage', 'history-static-image', 'AboutCompany[historyStaticImage]', historyStaticImg[0])">Завантажити</va-button>
                  <div v-if="errors.historyStaticImage" style="color: red;">{{ errors.historyStaticImage[0] }}</div>
                </va-card>
                <div style="margin-bottom: 15px;">
                  <span>History Static Content *</span>
                  <ckeditor v-model="historyStaticContent" :editor="editor" :config="editorConfig"></ckeditor>
                  <div v-if="errors.historyStaticContent" style="color: red;">{{ errors.historyStaticContent[0] }}</div>
                </div>
                <va-input
                  label="Mission Block Title *"
                  v-model="missionBlockTitle"
                  @input="delete errors.missionBlockTitle"
                  :error="!!errors.missionBlockTitle"
                  :error-messages="errors.missionBlockTitle"
                />
                <va-input
                  type="textarea"
                  label="Mission Block Description"
                  v-model="missionBlockDescription"
                  @input="delete errors.missionBlockDescription"
                  :error="!!errors.missionBlockDescription"
                  :error-messages="errors.missionBlockDescription"
                />
                <va-input
                  label="Our Values Title"
                  v-model="ourValuesTitle"
                  @input="delete errors.ourValuesTitle"
                  :error="!!errors.ourValuesTitle"
                  :error-messages="errors.ourValuesTitle"
                />
                <va-card
                  title="Our Values Array"
                  style="margin-bottom: 25px;"
                >
                  <va-button style="margin-bottom: 10px;" small @click="addInput">Додати</va-button>
                  <div v-for="(input, index) in ourValues" :key="index">
                    <va-input
                      v-model="ourValues[index]"
                    />
                    <va-button style="margin-bottom: 30px;" v-if="ourValues.length > 0" color="danger" small @click="removeInput(index)">Видалити</va-button>
                  </div>
                </va-card>
                <va-input
                  label="Our Values Block Image Text"
                  v-model="ourValuesBlockImageText"
                  @input="delete errors.ourValuesBlockImageText"
                  :error="!!errors.ourValuesBlockImageText"
                  :error-messages="errors.ourValuesBlockImageText"
                />
                <va-input
                  label="Our Values Block Image SubText"
                  v-model="ourValuesBlockImageSubtext"
                  @input="delete errors.ourValuesBlockImageSubtext"
                  :error="!!errors.ourValuesBlockImageSubtext"
                  :error-messages="errors.ourValuesBlockImageSubtext"
                />
                <va-card class="mb-3" title="Our Values Block Image">
                  <div v-if="ourValuesBlockImageExisting">
                    <a style="display: block;" target="_blank" :href="ourValuesBlockImageExisting"><img style="max-width: 400px;" :src="ourValuesBlockImageExisting"></a>
                    <div><va-button small color="danger" @click="ourValuesBlockImageExisting = deleteImage(ourValuesBlockImageId) ? '' : ourValuesBlockImageExisting">Видалити</va-button></div>
                  </div>
                  <span>Завантажити нове зображення</span>
                  <va-file-upload
                    type="gallery"
                    dropzone
                    @input="delete errors.ourValuesBlockImage"
                    v-model="ourValuesBlockImage"
                    style="margin-bottom: 25px;"
                  />
                  <va-button @click="uploadImage('ourValuesBlockImage', 'mission-image', 'AboutCompany[ourValuesBlockImage]', ourValuesBlockImage[0])">Завантажити</va-button>
                  <div v-if="errors.ourValuesBlockImage" style="color: red;">{{ errors.ourValuesBlockImage[0] }}</div>
                </va-card>
                <va-input
                  label="Association Block Title *"
                  v-model="associationBlockTitle"
                  @input="delete errors.associationBlockTitle"
                  :error="!!errors.associationBlockTitle"
                  :error-messages="errors.associationBlockTitle"
                />
                <va-card class="mb-3" title="Association Block Items">
                  <va-button
                    class="mb-3"
                    :to="{ name: '1lk-about-association-edit' }"
                  >
                    Додати
                  </va-button>
                  <va-button
                    class="mb-3"
                    :to="{ name: '1lk-about-association' }"
                  >
                    Всі
                  </va-button>
                  <div class="row">
                    <div
                      v-for="association in associationBlockItems"
                      :key="association.id"
                      stripe="success"
                      class="flex xs4 mb-2">
                      <va-card
                      >
                        <img style="max-height: 50px;" :src="apiUrl + association.image">
                        <div class="mb-1">{{ association.label }} </div>
                        <va-button
                          small
                          color="info"
                          :to="{ name: '1lk-about-association-edit', params: { id: association.id } }"
                        >
                          Редагувати
                        </va-button>
                      </va-card>
                    </div>
                  </div>
                </va-card>
                <va-input
                  label="Team Block Title"
                  v-model="teamBlockTitle"
                  @input="delete errors.teamBlockTitle"
                  :error="!!errors.teamBlockTitle"
                  :error-messages="errors.teamBlockTitle"
                />
                <va-input
                  type="textarea"
                  label="Team Block Description"
                  v-model="teamBlockDescription"
                  @input="delete errors.teamBlockDescription"
                  :error="!!errors.teamBlockDescription"
                  :error-messages="errors.teamBlockDescription"
                />
                <va-input
                  label="Team Block Show More Button *"
                  v-model="teamBlockShowMore"
                  @input="delete errors.teamBlockShowMoreButton"
                  :error="!!errors.teamBlockShowMoreButton"
                  :error-messages="errors.teamBlockShowMoreButton"
                />
                <va-card class="mb-3" title="Team Block Items">
                  <va-button
                    class="mb-3"
                    :to="{ name: '1lk-about-team-edit' }"
                  >
                    Додати
                  </va-button>
                  <va-button
                    class="mb-3"
                    :to="{ name: '1lk-about-team' }"
                  >
                    Всі
                  </va-button>
                  <div class="row">
                    <div
                      v-for="team in teamBlockItems"
                      :key="team.id"
                      stripe="success"
                      class="flex xs4 mb-2">
                      <va-card
                      >
                        <img style="max-height: 50px;" :src="apiUrl + team.image">
                        <div class="mb-1">{{ team.label }} </div>
                        <va-button
                          small
                          color="info"
                          :to="{ name: '1lk-about-team-edit', params: { id: team.id } }"
                        >
                          Редагувати
                        </va-button>
                      </va-card>
                    </div>
                  </div>
                </va-card>
                <va-input
                  label="Power Block Title *"
                  v-model="powerBlockTitle"
                  @input="delete errors.powerBlockTitle"
                  :error="!!errors.powerBlockTitle"
                  :error-messages="errors.powerBlockTitle"
                />
                <div style="margin-bottom: 15px;">
                  <span>Power Block Description</span>
                  <ckeditor v-model="powerBlockDescription" :editor="editor" :config="editorConfig"></ckeditor>
                  <div v-if="errors.powerBlockDescription" style="color: red;">{{ errors.powerBlockDescription[0] }}</div>
                </div>
                <va-input
                  label="Power Block Show More"
                  v-model="powerBlockShowMore"
                  @input="delete errors.powerBlockShowMoreButtonLabel"
                  :error="!!errors.powerBlockShowMoreButtonLabel"
                  :error-messages="errors.powerBlockShowMoreButtonLabel"
                />
                <va-input
                  label="Power Block Hide Text"
                  v-model="powerBlockHideText"
                  @input="delete errors.powerBlockHideTextButtonLabel"
                  :error="!!errors.powerBlockHideTextButtonLabel"
                  :error-messages="errors.powerBlockHideTextButtonLabel"
                />
                <va-card class="mb-3" title="Power Block Image">
                  <div v-if="powerBlockImgExisting">
                    <a style="display: block;" target="_blank" :href="powerBlockImgExisting"><img style="max-width: 400px;" :src="powerBlockImgExisting"></a>
                    <div><va-button small color="danger" @click="powerBlockImgExisting = deleteImage(powerBlockImgId) ? '' : powerBlockImgExisting">Видалити</va-button></div>
                  </div>
                  <span>Завантажити нове зображення</span>
                  <va-file-upload
                    type="gallery"
                    dropzone
                    @input="delete errors.powerBlockImage"
                    v-model="powerBlockImg"
                    style="margin-bottom: 25px;"
                  />
                  <va-button @click="uploadImage('powerBlockImage', 'power-image', 'AboutCompany[powerBlockImage]', powerBlockImg[0])">Завантажити</va-button>
                  <div v-if="errors.powerBlockImage" style="color: red;">{{ errors.powerBlockImage[0] }}</div>
                </va-card>
                <va-input
                  label="Social Responsibility Title"
                  v-model="socialResponsibilityTitle"
                  @input="delete errors.socialResponsibilityTitle"
                  :error="!!errors.socialResponsibilityTitle"
                  :error-messages="errors.socialResponsibilityTitle"
                />
                <div style="margin-bottom: 15px;">
                  <span>Social Responsibility Description</span>
                  <ckeditor v-model="socialResponsibilityDescription" :editor="editor" :config="editorConfig"></ckeditor>
                  <div v-if="errors.socialResponsibilityDescription" style="color: red;">{{ errors.socialResponsibilityDescription[0] }}</div>
                </div>
                <va-input
                  label="Social Responsibility Show More Button Text"
                  v-model="socialResponsibilityShowMore"
                  @input="delete errors.socialResponsibilityShowMoreButtonText"
                  :error="!!errors.socialResponsibilityShowMoreButtonText"
                  :error-messages="errors.socialResponsibilityShowMoreButtonText"
                />
                <va-input
                  label="Social Responsibility Hide Button Text"
                  v-model="socialResponsibilityHide"
                  @input="delete errors.socialResponsibilityHideButtonText"
                  :error="!!errors.socialResponsibilityHideButtonText"
                  :error-messages="errors.socialResponsibilityHideButtonText"
                />
                <va-input
                  label="Partner Block Title *"
                  v-model="partnerBlockTitle"
                  @input="delete errors.partnerBlockTitle"
                  :error="!!errors.partnerBlockTitle"
                  :error-messages="errors.partnerBlockTitle"
                />
                <va-card class="mb-3" title="Partners">
                  <va-button
                    class="mb-3"
                    :to="{ name: '1lk-home-partners-edit' }"
                  >
                    Додати
                  </va-button>
                  <va-button
                    class="mb-3"
                    :to="{ name: '1lk-home-partners' }"
                  >
                    Всі
                  </va-button>
                  <div class="row">
                    <div
                      v-for="partner in partners"
                      :key="partner.id"
                      stripe="success"
                      class="flex xs4 mb-2">
                      <va-card
                      >
                        <img style="max-height: 30px;" :src="apiUrl + partner.image">
                        <div class="mb-1">{{ partner.label }} </div>
                        <va-button
                          small
                          color="info"
                          :to="{ name: '1lk-home-partners-edit', params: { id: partner.id } }"
                        >
                          Редагувати
                        </va-button>
                      </va-card>
                    </div>
                  </div>
                </va-card>
                <va-button @click="submit()">Save</va-button>
              </div>
              <div class="flex xs12" v-if="tabValue === 1">
                <va-input
                  label="Meta Title"
                  v-model="metaTitle"
                  @input="delete errors.metaTitle"
                  :error="!!errors.metaTitle"
                  :error-messages="errors.metaTitle"
                />
                <va-input
                  label="Meta Description"
                  v-model="metaDescription"
                  @input="delete errors.metaDescription"
                  :error="!!errors.metaDescription"
                  :error-messages="errors.metaDescription"
                />
                <va-input
                  label="OG Title"
                  v-model="OgTitle"
                  @input="delete errors.OgTitle"
                  :error="!!errors.OgTitle"
                  :error-messages="errors.OgTitle"
                />
                <va-input
                  label="OG Description"
                  v-model="OgDescription"
                  @input="delete errors.OgDescription"
                  :error="!!errors.OgDescription"
                  :error-messages="errors.OgDescription"
                />
                <va-card class="mb-3" title="OG Image">
                  <a v-if="OgImageExisting" style="display: block;" target="_blank" :href="OgImageExisting"><img style="max-width: 400px;" :src="OgImageExisting"></a>
                  <div v-if="OgImageExisting">
                    <a style="display: block;" target="_blank" :href="OgImageExisting"><img style="max-width: 400px;" :src="OgImageExisting"></a>
                    <div><va-button small color="danger" @click="OgImageExisting = deleteImage(OgImageId) ? '' : OgImageExisting">Видалити</va-button></div>
                  </div>
                  <span>Завантажити нове зображення</span>
                  <va-file-upload
                    type="gallery"
                    dropzone
                    v-model="OgImage"
                    style="margin-bottom: 25px;"
                  />
                  <va-button @click="uploadImage('image', null, 'SeoEntity[image]', OgImage[0], 'common\\models\\SeoEntity')">Завантажити</va-button>
                  <div v-if="errors.image" style="color: red;">{{ errors.image[0] }}</div>
                </va-card>
                <va-input
                  label="seo text title"
                  v-model="seoTextTitle"
                  @input="delete errors.seoTextTitle"
                  :error="!!errors.seoTextTitle"
                  :error-messages="errors.seoTextTitle"
                />
                <div style="margin-bottom: 15px;">
                  <span>seo text</span>
                  <ckeditor v-model="seoText" :editor="editor" :config="editorConfig"></ckeditor>
                </div>
                <va-input
                  label="seo text see more text"
                  v-model="seoTextSeeMore"
                  @input="delete errors.seoTextSeeMore"
                  :error="!!errors.seoTextSeeMore"
                  :error-messages="errors.seoTextSeeMore"
                />
                <va-input
                  label="Seo Text Hide Text"
                  v-model="seoTextHide"
                  @input="delete errors.seoTextHide"
                  :error="!!errors.seoTextHide"
                  :error-messages="errors.seoTextHide"
                />
                <va-button @click="submit()">Save</va-button>
              </div>
            </div>
          </div>
        </div>
      </va-card>
    </div>
  </div>
</template>

<script>
import * as axios from 'axios'
import Vue from 'vue'
import CKEditor from '@ckeditor/ckeditor5-vue'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import '@ckeditor/ckeditor5-build-classic/build/translations/uk'

export default {
  name: 'grid',
  components: {
    ckeditor: CKEditor.component,
  },
  data () {
    return {
      topImage: [],
      topImageExisting: '',
      topImageId: '',
      topImageMobile: [],
      topImageMobileExisting: '',
      topImageMobileId: '',
      breadcrumbLabel: '',
      title: '',
      shortDescription: '',
      advantagesBlockTitle: '',
      advantagesBlockDescription: '',
      advantages: [],
      historyBlockTitle: '',
      historyItems: [],
      historyStaticImg: [],
      historyStaticImgId: '',
      historyStaticImgExisting: '',
      historyStaticContent: '',
      missionBlockTitle: '',
      missionBlockDescription: '',
      ourValuesTitle: '',
      ourValues: [],
      ourValuesBlockImageText: '',
      ourValuesBlockImageSubtext: '',
      ourValuesBlockImage: [],
      ourValuesBlockImageId: '',
      ourValuesBlockImageExisting: '',
      associationBlockTitle: '',
      associationBlockItems: [],
      teamBlockTitle: '',
      teamBlockDescription: '',
      teamBlockShowMore: '',
      teamBlockItems: [],
      powerBlockTitle: '',
      powerBlockDescription: '',
      powerBlockShowMore: '',
      powerBlockHideText: '',
      powerBlockImg: [],
      powerBlockImgId: '',
      powerBlockImgExisting: '',
      socialResponsibilityTitle: '',
      socialResponsibilityDescription: '',
      socialResponsibilityShowMore: '',
      socialResponsibilityHide: '',
      partnerBlockTitle: '',
      partners: [],

      seoId: null,
      metaTitle: '',
      metaDescription: '',
      OgTitle: '',
      OgDescription: '',
      OgImage: [],
      OgImageId: '',
      OgImageExisting: '',
      seoTextTitle: '',
      seoText: '',
      seoTextSeeMore: '',
      seoTextHide: '',

      editor: ClassicEditor,
      editorConfig: {
        language: 'uk',
        simpleUpload: {
          uploadUrl: `${process.env.VUE_APP_1LK_API_URL}/admin/editor/upload`,
          headers: {
            Authorization: localStorage.getItem('token'),
          },
        },
      },

      errors: [],

      tabTitles: ['Main', 'Seo'],
      tabValue: 0,
    }
  },
  created () {
    this.fetch()
    const generateRandomString = (length = 6) => Math.random().toString(20).substr(2, length)
    this.sign = generateRandomString(32)
  },
  computed: {
    computedStyle () {
      return {
        backgroundColor: this.$themes.primary,
      }
    },
    locale () {
      return Vue.i18n.locale()
    },
    apiUrl () {
      return process.env.VUE_APP_1LK_ADMIN
    },
  },
  watch: {
    locale: function (value) {
      this.fetch()
    },
  },
  methods: {
    addInput () {
      this.ourValues.push('')
    },
    removeInput (index) {
      this.ourValues.splice(index, 1)
    },
    uploadImage (attr, entityAttribute, param, file, model = 'common\\modules\\kvs\\models\\AboutCompany') {
      if (!file) {
        return
      }
      const formData = new FormData()
      formData.append('model_name', model)
      formData.append('attribute', attr)
      formData.append('entity_attribute', entityAttribute)
      formData.append(param, file)
      formData.append('file_id', '0')
      formData.append('multiple', '0')
      if (entityAttribute === null) {
        formData.append('id', this.seoId ? this.seoId : '0')
      } else {
        formData.append('id', '0')
        formData.append('entity_attribute', entityAttribute)
      }

      axios.post(`${process.env.VUE_APP_1LK_API_URL}/admin/upload?lang=${this.locale}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
        .then(response => {
          this.showToast('Successful upload')
        })
        .catch(error => {
          console.log(error)
          this.showToast('Error')
        })
    },
    async deleteImage (id) {
      return axios.delete(`${process.env.VUE_APP_1LK_API_URL}/admin/upload/${id}?lang=${this.locale}`)
        .then(() => {
          this.showToast('Successfully deleted')
        })
        .catch(() => {
          this.showToast('Error')
        })
    },
    submit () {
      const data = {
        breadcrumbLabel: this.breadcrumbLabel,
        title: this.title,
        shortDescription: this.shortDescription,
        advantagesBlockTitle: this.advantagesBlockTitle,
        advantagesBlockDescription: this.advantagesBlockDescription,
        historyBlockTitle: this.historyBlockTitle,
        historyStaticContent: this.historyStaticContent,
        missionBlockTitle: this.missionBlockTitle,
        missionBlockDescription: this.missionBlockDescription,
        ourValuesTitle: this.ourValuesTitle,
        ourValuesArray: this.ourValues,
        ourValuesBlockImageText: this.ourValuesBlockImageText,
        ourValuesBlockImageSubtext: this.ourValuesBlockImageSubtext,
        associationBlockTitle: this.associationBlockTitle,
        teamBlockTitle: this.teamBlockTitle,
        teamBlockDescription: this.teamBlockDescription,
        teamBlockShowMoreButton: this.teamBlockShowMore,
        powerBlockTitle: this.powerBlockTitle,
        powerBlockDescription: this.powerBlockDescription,
        powerBlockShowMoreButtonLabel: this.powerBlockShowMore,
        powerBlockHideTextButtonLabel: this.powerBlockHideText,
        socialResponsibilityTitle: this.socialResponsibilityTitle,
        socialResponsibilityDescription: this.socialResponsibilityDescription,
        socialResponsibilityShowMoreButtonText: this.socialResponsibilityShowMore,
        socialResponsibilityHideButtonText: this.socialResponsibilityHide,
        partnerBlockTitle: this.partnerBlockTitle,

        SeoEntity: {
          title: this.metaTitle,
          description: this.metaDescription,
          og_title: this.OgTitle,
          og_description: this.OgDescription,
          seo_text_title: this.seoTextTitle,
          seo_text: this.seoText,
          seo_text_see_more_text: this.seoTextSeeMore,
          seo_text_hide_text: this.seoTextHide,
        },
      }
      axios.post(`${process.env.VUE_APP_1LK_API_URL}/admin/about-texts?lang=${this.locale}`, data)
        .then(response => {
          this.showToast('Success')
        })
        .catch(error => {
          if (error.response.status === 422) {
            this.errors = error.response.data.errors
          }
          console.log(error)
          this.showToast('Error')
        })
    },
    fetch () {
      axios.get(`${process.env.VUE_APP_1LK_API_URL}/admin/about-texts?lang=${this.locale}`)
        .then(response => {
          this.topImageExisting = response.data.topImage ? process.env.VUE_APP_1LK_ADMIN + response.data.topImage : ''
          this.topImageId = response.data.topImageId
          this.topImageMobileExisting = response.data.topMobileImage ? process.env.VUE_APP_1LK_ADMIN + response.data.topMobileImage : ''
          this.topImageMobileId = response.data.topMobileImageId
          this.historyStaticImgExisting = response.data.historyStaticImage ? process.env.VUE_APP_1LK_ADMIN + response.data.historyStaticImage : ''
          this.historyStaticImgId = response.data.historyStaticImageId
          this.ourValuesBlockImageExisting = response.data.ourValuesBlockImage ? process.env.VUE_APP_1LK_ADMIN + response.data.ourValuesBlockImage : ''
          this.ourValuesBlockImageId = response.data.ourValuesBlockImageId
          this.powerBlockImgExisting = response.data.powerBlockImage ? process.env.VUE_APP_1LK_ADMIN + response.data.powerBlockImage : ''
          this.powerBlockImgId = response.data.powerBlockImageId

          this.breadcrumbLabel = response.data.breadcrumbLabel
          this.title = response.data.title
          this.shortDescription = response.data.shortDescription
          this.advantagesBlockTitle = response.data.advantagesBlockTitle
          this.advantagesBlockDescription = response.data.advantagesBlockDescription
          this.historyBlockTitle = response.data.historyBlockTitle
          this.historyStaticContent = response.data.historyStaticContent ? response.data.historyStaticContent : ''
          this.missionBlockTitle = response.data.missionBlockTitle
          this.missionBlockDescription = response.data.missionBlockDescription
          this.ourValuesTitle = response.data.ourValuesTitle
          this.ourValues = response.data.ourValues ? JSON.parse(response.data.ourValues) : []
          this.ourValuesBlockImageText = response.data.ourValuesBlockImageText
          this.ourValuesBlockImageSubtext = response.data.ourValuesBlockImageSubtext
          this.associationBlockTitle = response.data.associationBlockTitle
          this.teamBlockTitle = response.data.teamBlockTitle
          this.teamBlockDescription = response.data.teamBlockDescription
          this.teamBlockShowMore = response.data.teamBlockShowMoreButton
          this.powerBlockTitle = response.data.powerBlockTitle
          this.powerBlockDescription = response.data.powerBlockDescription ? response.data.powerBlockDescription : ''
          this.powerBlockShowMore = response.data.powerBlockShowMoreButtonLabel
          this.powerBlockHideText = response.data.powerBlockHideTextButtonLabel
          this.socialResponsibilityTitle = response.data.socialResponsibilityTitle
          this.socialResponsibilityDescription = response.data.socialResponsibilityDescription ? response.data.socialResponsibilityDescription : ''
          this.socialResponsibilityShowMore = response.data.socialResponsibilityShowMoreButtonText
          this.socialResponsibilityHide = response.data.socialResponsibilityHideButtonText
          this.partnerBlockTitle = response.data.partnerBlockTitle

          this.seoId = response.data.seo.id
          this.metaTitle = response.data.seo.title
          this.metaDescription = response.data.seo.description
          this.OgTitle = response.data.seo.og_title
          this.OgDescription = response.data.seo.og_description
          this.seoTextTitle = response.data.seo.seo_text_title
          this.seoText = response.data.seo.seo_text ? response.data.seo.seo_text : ''
          this.seoTextSeeMore = response.data.seo.seo_text_see_more_text
          this.seoTextHide = response.data.seo.seo_text_hide_text

          this.OgImageExisting = response.data.seo.og_image ? process.env.VUE_APP_1LK_ADMIN + response.data.seo.og_image : null
          this.OgImageId = response.data.seo.og_image_id

          this.partners = response.data.partners
          this.teamBlockItems = response.data.teams
          this.associationBlockItems = response.data.associations
          this.historyItems = response.data.histories
          this.advantages = response.data.advantages
        })
        .catch(error => {
          console.log(error)
          this.showToast('Error')
        })
    },
  },
}
</script>

<style lang="scss">
.grid {
  &__container {
    min-height: 3rem;
    color: $white;
    border-radius: 0.5rem;
  }
}

.va-file-upload-gallery-item {
  flex-basis: unset;
  max-width: 400px;
  width: unset;
}
</style>
